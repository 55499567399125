.app.dark{
    background-color: #222;
    color: rgb(156, 156, 156);

    .chartGrid{
        stroke: rgba(228, 228, 228, 0.219);
    }

    .table{
        background-color: #111;

        .tableCell{
            color: gray;
        }
    }

    .navbar{
        color: gray;
        border-color:#333 ;

        .search{
            border-color: gray;
        }
    }

    .sidebar{
        background-color: #222;
        border-color: gray;

        .top{
            .logo{
                color: #999;
            }
        }

        hr{
            border-color: #333;
        }

        ul{
            li{
                &:hover{
                    background-color: #333;
                }
                .icon{
                    color: #999;
                }
            }
        }
    }

    .datatable{
        .datagrid{
            color: gray;
            border: none;

            .viewButton,.deleteButton,.cellWithStatus{
                color: gray;
                border: none;
            }
        }
    }

    input{
        background-color: transparent;
    }
    
}