.dashboard-chart {
    margin: 10px;
    padding: 6px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(169, 168, 168, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(152, 152, 152, 0.47);
    color: gray;
    border-radius: 5px;
    // background-image: linear-gradient(90deg, #fbcb46, #fae034);

    .title{
        margin-bottom: 20px;
    }

    .chartGrid{
        stroke: rgb(228, 225, 225);
    }

    .recharts-cartesian-axis-ticks {
        font-size: 10px;
    }

    .recharts-default-legend {
        font-size: 14px;
    }
}