.stockledgerMain {
    // padding: 20px;

    .stockledgerTop {
        display: flex;
        width: 100%;
        gap: 10px;
        // height: 6vh;
        
        .singleContainer {
            flex: 8;

            .top {
                display: flex;
                padding: 0 10px;
                gap: 20px;
                justify-content: space-between;

                .left {
                    display: flex;
                    // width: 50%;
                    flex: 1;
                    // -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    padding: 10px 0px;
                    margin: 0 10px;
                    

                    label{
                        font-weight: 600;
                        font-size: 14px;
                        padding: 3px 5px;
                    }

                    .dropdownInputStyle{
                        height: 1.8rem;
                        width: 70%;
                        border-radius: 6px;
                        padding: 0 10px;
                        font-size: 12px;
                        border: 1px solid lightgray;
                    }

                    // .field{
                    //     padding-bottom: 10px;
                    //     font-size: 12px !important;
                
                    //     .trxn_date_range_class{
                    //         // padding-left: 20px !important;
                    //         padding-right: 10px;
                    //         font-weight: bold;
                    //         padding-bottom: 3px;
                    //     }
                    //     #trxn_date_range{
                    //         height: 20px;
                
                    //         .p-inputtext.p-component.p-filled {
                    //             font-size: 12px;
                    //         }
                    //     }
                    // }
                }

                .right{
                    // width: 50%;
                    flex: 3;
                    display: flex;
                    // -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    padding: 10px 0px;
                    width: 70%;
                    // gap: 20px;

                    label {
                        font-weight: bold;
                        width: 100%;
                    }

                    .react-datepicker__input-container{
                        display: flex !important;

                        .react-datepicker__close-icon{
                            width: max-content;
                            padding: 0 10px;
                            position: relative;
                        }
                    }

                    .viewButton{
                        padding: 2px 5px;
                        border-radius: 5px;
                        color: darkblue;
                        border: 1px solid rgba(0,0,139,0.596);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .mini-tiles {
        flex: 6;
        display: flex;
        // -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        // padding: 0px 20px;
        gap: 20px;

        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid rgba(0,0,139,0.596);
            cursor: pointer;
        }

        .tile {
            height: 3rem;
        }
    }

    
    
}

.stockledger-page-loading {
    position: absolute;
    top: 48vh;
    left: 53vw;

}