.tile{
    display: flex;
    justify-content: space-between;
    // flex: 1;
    width: 33.33%;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    border-radius: 10px;
    height: 2rem !important;
    // height: 100px;
    // background-image: linear-gradient(90deg, #fbcb46, #fae034);

    .tile-left, .tile-right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font-weight: bold;
            font-size: 12px;
            color: gray;
        }

        .counter {
            font-size: 14px;
            font-weight: 600;
        }

        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
                color: green;
            }
            &.negative {
                color: red;
            }
        }

        .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}