.single{
    display: flex;
    width: 100%;
    // height: 26.5vh;

    .singleContainer{
        flex: 6;

        .top{
            padding: 0 10px;
            display: flex;
            gap: 20px;

            .left{
                // width: 20%;
                flex: 1 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 10px;
                position: relative;

                .editButton{
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 3px 5px;
                    font-size: 12px;
                    color: #7451f8;
                    background-color: #7551f818;
                    cursor: pointer;
                    border-radius: 0px 0px 0px 5px;
                }

                .title{
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                .item{
                    display: flex;
                    gap: 10px;

                    .itemImg{
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .details{
                        .itemTitle{
                            font-size: 1.5rem !important;
                            margin-bottom: 5px;
                            color: #555;
                        }

                        .detailItem{
                            margin-bottom: 3px;
                            font-size: 13px !important;

                            .itemKey{
                                font-weight: bold;
                                color: gray;
                                margin-right: 5px;
                            }

                            .itemValue{
                                font-weight: 300;
                            }
                        }
                    }
                }
            }

            .right{
                flex: 3 1;
                // width: 80%;
            }
        }
    

        .bottom{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 10px 20px;
        }

        .title{
            font-size: 16px;
            color: rgba(136, 136, 136, 0.789);
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
}