.sidebar{
    // flex: 1 1;
    width: 13vw;
    border-right: 0.5px solid rgb(198, 198, 198);
    min-height: 100vh;
    background-color: white;
    // width: 20vw;

    .top {
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            font-size: 20px;
            font-weight: bold;
            // color: #ffd000d6;
            color: #6439ff;
        }

    }

    hr {
        height: 0;
        border: 0.5px solid rgb(10, 10, 10);
        margin: 0 0 0.5rem 0;
    }
    
    .center {
        padding-left: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .active li {
                background-color: #7b61f7;

                .icon {
                    font-size: 15px;
                    color: white !important;
                    // color: #9e8204;
                }

                span {
                    color: white;
                }
            }

            .title {
                font-size: 12px;
                font-weight: bold;
                color: 999;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ece8ff;
                    // background-color: #f3ca12;
                }

                .icon {
                    font-size: 15px;
                    color: #7451f8;
                    // color: #9e8204;
                }

                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;
                }
            }

            // a li{
            //     background-color: red;
            // }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOptions {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
        }
    }
}