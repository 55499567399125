
/* DataTableDemo.css */

.dashboard-grid {
    // height: 100%;
    margin: 10px;
    padding: 6px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 1);
    border-radius: 5px;
    width: calc(100vw - 270px);

    .p-datatable-wrapper{
        // height: 48vh;
        height: calc(100vh - 565px);
    }

    // .card {
    //     --bs-card-border-color: None;
        
    //     .p-datatable {
    //         height: 49vh;

    //         .p-datatable-wrapper{
    //             // height: 48vh;
    //             height: calc(100vh - 450vh);
    //         }
    //     }
    // }


    .p-selectable-row{
        height: 3rem;
    }

    .p-datatable-tbody{
        font-size: 10px;
        // font-weight: 400;
    }
}

.dashboard-grid .table-header {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .dashboard-grid .table-header {
        align-items: flex-start;
    }
}
.dashboard-grid .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.dashboard-grid .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.dashboard-grid .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .dashboard-grid .p-toolbar {
        flex-wrap: wrap;
    }
    .dashboard-grid .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .dashboard-grid .table-header {
        flex-direction: column;
    }
    .dashboard-grid .table-header .p-input-icon-left, .dashboard-grid .table-header input {
        width: 100%;
    }
}
                 