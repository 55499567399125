.home {
    display: flex;

    .homeContainer {
        width: 87vw;

        .home-main{
            // display: flex;
            // flex-direction: column;
            // height: 100%;

            // .widgets {
            //     height: 10%;
            // }
            
            .widgets, .charts {
                display: flex;
                padding: 10px 10px 0px 10px;
                gap: 10px;
            }

            .charts {
                padding: 5px 10px;
            }

            .listContainer {
                -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 10px;
                margin: 10px;

                .listTitle{
                    font-weight: 500;
                    color: gray;
                    margin-bottom: 15px;
                }
            }
        }

        // .grid-main {
        //     display: flex;
        //     height: 55%;
        // }

        // .dashboard-chart-main {
        //     display: flex;
        //     height: 35%;
        // }
    }
}


