.main-layout-panel {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .left-panel-main {
        width: 13vw;
    }

    .right-panel-main {
        width: 87vw;
    }
}