.widget{
    // display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 8px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(130, 130, 130, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(132, 132, 132, 0.47);
    border-radius: 10px;
    height: 4rem;
    // background-image: linear-gradient(90deg, #fbcb46, #fae034);

    

    .widget-container{
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
    
    .title p {
        font-weight: bolder;
        font-size: 11px;
        color: rgb(30, 30, 30);
        text-align: center;
        margin-bottom: 5px;
    }

    .left, .right {
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;


        .counter {
            font-size: 11px;
            font-weight: bold;
            color: gray;
        }

        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
                color: green;
            }
            &.negative {
                color: red;
            }
        }

        .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}


.deposits-widget {
    // background-color: rgb(182, 253, 182);
}

.withdrawals-widget {
    // background-color: rgb(251, 188, 185);
}

.balance-widget {
    // background-color: rgb(251, 233, 174);
}

.refinary-widget {
    // background-color: rgb(186, 230, 249);
}